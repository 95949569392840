import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);

  const openPrivacyPolicy = () => {
    setIsOpen(true);
  };

  const openPhysicalAddress = () => {
    setAddressOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const closeAddressDaiog = () => {
    setAddressOpen(false);
  };

  return (
    <div className="footer">
      <a className="footer-text" onClick={openPhysicalAddress}>
        Physical Address
      </a>
      <a className="footer-text" onClick={openPrivacyPolicy}>
        Privacy Policy
      </a>

      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogContent className="dialog-contects">
          <p className="privacy-policy-text">
            No mobile information will be shared with third parties/affiliates
            for marketing/promotional purposes. All other categories exclude
            text messaging originator opt- in data and consent; this information
            will not be shared with any third parties
          </p>
        </DialogContent>
      </Dialog>

      <Dialog open={addressOpen} onClose={closeAddressDaiog}>
        <DialogContent className="dialog-contects">
          <p className="privacy-policy-text">
            16192 Coastal Hwy, Lewes, DE 19958, United States
          </p>
          <p className="privacy-policy-text">+1 (651) 300-6198</p>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Footer;
