import po from "../Images/po.png"
import { motion } from "framer-motion";
import "../App.css"

function Home() {
  return (
    <motion.div 
    initial={{opacity:"0"}}
    animate={{opacity:"1"}}
    transition={{delay:"0.3"}}
    className="Home">
      <img style={{display:"flex", flexDirection:"row", alignContent:"center", justifyContent:"center", width:"300px",height:"300px" }} className="Logo" src={po} alt="Company Logo" />
    </motion.div>
  );
}

export default Home;
